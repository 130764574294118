@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  li {
    @apply p-4;
  }
}

body {
  background-color: "storm";
}

.introBackground {
  background-image: url("./assets/bg/background.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/* Toggle switch */
.toggledark {
  background-image: linear-gradient(#be375f, #d8a28c);
}

.togglelight {
  background-image: linear-gradient(#000, #000);
}

.second {
  position: relative;
}

.notch {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: white;
  position: absolute;
  z-index: 1;
  transition: all 0.3s ease-in-out;
}

.second > .notch {
  transform: translate(30px, 0);
}
